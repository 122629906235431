import gql from 'graphql-tag';
import { BuildSimpleGQLBuilder } from '../utils/simpleGQLBuilder';

export const SubOrganizationFragment = gql`
  fragment sub_org on SubOrganization {
    id
    name
    updated_at
    created_at
    privacy_email
    address
    unit
    phone
    city
    state
    zip_code
    country
    timezone
    is_parent
  }
`;

export const SubOrgGQLBuilder = BuildSimpleGQLBuilder({
  fragment: SubOrganizationFragment,
  path: 'sub-organizations',
});
